.table-container {
    margin-left: auto;
    margin-right: auto;
    color: black;
}

.filter {
    margin-bottom: 1vh;
    background-color: transparent;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
    font-size: 2vh;
}

.filter:focus {
    outline: none;
}

.filter::placeholder{
    color: black;
    font-size: 2vh;
    opacity: 1; /* Firefox */
}

.up-arrow {
    width: 1vh;
    margin-left: 1vh;
}

.down-arrow {
    margin-left: 1vh;
    width: 1vh;
    transform: rotate(180deg);
}
