.App {
  text-align: center;
}

.header-div {

}

.all {
  display: flex;
  flex-direction: column;

}
