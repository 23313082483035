.outerContainerSimple {
    display: flex;
    margin-right: 10vh;
    margin-top: 2vh;
    margin-left: 10vh;
    flex-direction: column;
    border-style: solid;
    border-width: 0px;
    background-color: #E7E7E7;
    border-radius: 4%;
    padding: 1vh;
}


.statName {
    align-items: flex-start;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;

    color: #434343;
}

.statValue {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;

    color: #000000;

}

.statRowContainer {
    display: flex;
    flex-direction: row;
}


