.outerContainer {
    display: flex;
    margin-right: 10vh;
    margin-left: 10vh;
    flex-direction: row;
    justify-content: space-between;
    border-style: solid;
    border-width: 0px;
    background-color: #E7E7E7;
    border-radius: 4%;
    padding: 1.5vh;
}

.image {
    border-radius: 25%;
    align-self: center;
}

.prefix {
    margin-top: 1.4vh;
    align-self: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 15px;

    color: #000000;
}

.rp {
    margin-top: 2vh;
    align-self: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 15px;

    color: #484848;
}

.leftContainer {
    display: flex;
    flex-direction: column;
    border-width: 0px;
    border-style: solid;
}

.rightContainer {
    margin-left: 3vh;
    display: flex;
    flex-direction: column;
    border-style: solid;
    border-width: 0px;
}

.name {
    margin-top: 1vh;
    align-self: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 15px;

    color: #000000;

}

.mmrContainer {
    margin-top: 4vh;
    align-self: center;
    display: flex;
    flex-direction: row;
}

.mmr {
    margin-top: 1vh;
    margin-left: 0.5vh;
    align-self: start;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 15px;

    color: #000000;
}

.mmrTag {
    margin-top: 1vh;
    align-self: start;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 15px;

    color: #484848;
}

.peakMmrContainer {
    margin-top: 0.2vh;
    align-self: center;
    display: flex;
    flex-direction: row;
}

.peakmmr {
    margin-top: 1vh;
    margin-left: 0.5vh;
    align-self: start;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 15px;

    color: #000000;
}

.peakMmrTag {
    margin-top: 1vh;
    align-self: start;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 15px;

    color: #484848;
}

.winRateTag {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 15px;

    color: #6D6D6D;
}

.winRateContainer {
    display: flex;
    margin-top: 1vh;
    flex-direction: row;
    align-self: end;
}

.recordWinContainer {
    margin-top: 3vh;
}

.winRateValue {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 15px;

    color: #408647;
}

.peakRankTag {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 15px;

    color: #6D6D6D;
}

.peakRankContainer {
    display: flex;
    margin-top: 1vh;
    flex-direction: row;
    align-self: center;
}

.peakRankValue {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 15px;

    color: #6D6D6D;
}

.recordContainer {
    margin-top: 3vh;
    align-self: start;
}

.record {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 15px;

    color: #6D6D6D;
}

.win, .loss, .unplayed, .draw {
    font-size: 2.4vh;
}

.loss {
    color: #b42424;
}

.draw {
    color: #6a6d6b;
}

.unplayed {
    color: #ffffff;
}

.win {
    color: #408647;
}

.promoContainer {
    margin-top: 0.4vh;
    align-self: center;
    gap: 2px;
    display: flex;
    flex-direction: row;
}

