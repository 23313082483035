.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px;
  position: relative;
  border-radius: 10px;
  background-color: #001064;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}

.logo {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-left: 5vh;
  margin-top: 0;

}

.logo p {
  text-align: center;
  font-size: 5vh;
  font-family: Light;
}

.search {
  margin-left: 5vh;
  display: flex;
  align-items: center;
}

#player-search {
  background-color: transparent;
  border: 2px solid white;
  border-radius: 8px;
  width: 28vh;
  height: 4vh;
  color: white;
  font-size: 2vh;
  text-indent: 1vh;
}

#player-search::placeholder {
  color: white;
  font-size: 2vh;
  opacity: 1; /* Firefox */
}

#player-search:focus {
  outline: none;
}

.pages {
  margin-left: 10vh;
  border: none;
  width: 40vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.pages p {
  font-size: 3vh;
  font-weight: bold;
}

.text-link {
  color: inherit;
  text-decoration: inherit;
}

.text-link:hover {
  color: #C4C4C4;
}



