.leaderboard-page {
    display: flex;
    flex-direction: column;
}

.users {
    display: none;
}

.hover {
    cursor: pointer;
}

.table-container {
    margin-top: 1vh;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
}

.table-container table {
    border-spacing: 0;
    border: 1.5px solid #cacaca;
}


.table-container td, th {
    text-align: center;
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1.5px solid #cacaca;
    border-right: 1.5px solid #cacaca;

}


.table-container th:last-child {
    border-right: 0;
}

.table-container td:last-child {
    border-right: 0;
}

.table-container tr:last-child td {
    border-bottom: 0;
}

.table-container tr td:first-child, .season-select-container {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.table-container tr:nth-child(even) {
    background-color: #fefefe;
}

.table-container th {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    background-color: #fefefe;
}

.season-select-container {
    margin-right: 0;
    margin-left: auto;
    width: 100%;
    min-width: 12ch;
    max-width: 12ch;
    border: 1px solid #777;
    border-radius: 0.25em;
    padding: 0.25em 0.5em;
    font-size: 1.25rem;
    cursor: pointer;
    line-height: 1.1;
    display: grid;
    background-color: #fff;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
    grid-template-areas: "select";
    align-items: center;
    position: relative;
}


.season-select-align {
    align-content: end;
    text-align: right;
    display:flex;
}

select {
    outline:none;
    appearance: none;
    background-color: transparent;
    border: none;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
}

.season-select-container::after {
    content: "";
    width: 0.8em;
    height: 0.5em;
    background-color: #777;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    justify-self: end;
}

select,
.season-select-container:after {
    grid-area: select;
}

select:focus + .focus {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 2px solid blue;
    border-radius: inherit;
}




