.redMatchResult {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    background: linear-gradient(to bottom, #E84056 0, #E84056 10px, #FFF0F3 0% , #FFF0F3 50%);
    width: 50vw;
    height: 140px;

    border-radius: 10px;
}

.blueMatchResult {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    background: linear-gradient(to bottom, #5283E5 0, #5283E5 10px, #ECF2FF 0% , #ECF2FF 50%);
    width: 50vw;
    height: 140px;

    border-radius: 10px;
}

.drawMatchResult {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    background: linear-gradient(to bottom, #6a6f7b 0, #6a6f7b 10px, #ffffff 0% , #ffffff 50%);
    width: 50vw;
    height: 140px;
    border-radius: 10px;
}





.mainStats {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 12px;
}

.bluePlayers {
    display: flex;
    margin: 10px;
    flex-direction: column;
    align-self: center;
    align-items: flex-start;
    flex: 1;
    gap: 10px;
}

.redPlayers {
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: flex-end;
    flex: 1;
    gap: 10px;

}

.losingTeam {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;

    color: #817D7D;
}

 a {
    text-decoration: none;
}

 a:hover {
     text-decoration: underline;
 }

.losingTeam > a {
    color: gray;
}

.winningTeam {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
}

.bold {
    /*text-decoration: underline;*/
    font-weight: 900;
}

.winningTeam > a {
    color: black;
}

.drawTeam {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
}

.drawTeam > a {
    color: black;
}

.scoreContainer {
    flex: 1;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;


}

.score {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 61px;
    text-align: center;
}

.time {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
}


.blueShowMoreButton {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    background: #D5E3FE;
    border-radius: 10px;
    height: 20px;
    align-items: center;
    justify-content: center;
    z-index: 4;
}

.blueShowMoreButton:hover {

    background: #c1d2f4;

}

.redShowMoreButton {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    background: #FFD8D9;
    border-radius: 10px;
    height: 20px;
    align-items: center;
    justify-content: center;
    z-index: 4;
}

.redShowMoreButton:hover {

    background: #f8c4c5;

}

.drawShowMoreButton {
    display: flex;
    cursor: pointer;
    margin-top: 1px;
    flex-direction: row;
    background: #e6e2e2;
    border-radius: 10px;
    height: 20px;
    align-items: center;
    justify-content: center;
    z-index: 4;
}

.drawShowMoreButton:hover {

    background: #b7b2b2;

}

.drawMore {
    display: flex;
    width: 40vw;
    flex-direction: row;
    background: #e6e2e2;
    border-radius: 10px;
    justify-content: space-around;
    align-self: center;
}

.redMore {
    display: flex;
    flex-direction: row;
    width: 40vw;

    background: #FFD8D9;
    border-radius: 10px;
    justify-content: space-around;
    align-self: center;
}

.blueMore {
    display: flex;
    flex-direction: row;
    width: 40vw;
    background: #D5E3FE;
    border-radius: 10px;
    justify-content: space-between;
    align-self: center;
}



.statName {
    display: flex;
    flex: 1 1 0px;
    flex-direction: column;
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

}

.blueStatVals {
    flex: 1 1 0px;
   text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
}

.redStatVals {
    flex: 1 1 0px;
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
}

.gainWon {
    color: green;

}

.gainLost {
    color: red;
}

.averagemmr {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
}

.nameGain {
    display: flex;
    flex-direction: row;
    gap: 3px;
}

.playerEnd {
    display: flex;
    flex-direction: column;
    align-items: end;
}

.player {
    display: flex;
    flex-direction: column;
    align-items: start;
}




@media screen and (max-width: 600px) {
    .mainStats {
        margin-right: 7vw;
    }
    .redMatchResult {
        width: 90vw;
    }

    .blueMatchResult {
        width: 90vw;

    }

    .drawMatchResult {
        width: 90vw;
    }

    .score {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 61px;
        text-align: center;
    }

    .redMore {
        width: 90vw;
    }

    .blueMore {
        width: 90vw;

    }

    .drawMore {
        width: 90vw;
    }
}


