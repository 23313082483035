.matchResultContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.pageCont {
    display: flex;
    flex-direction: row;
    gap: 10px;
}
